import React, { useEffect, useRef, useState } from 'react';
import useOnScreen from './useOnScreen';

const LazyBox = (props) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [show,setShow] = useState()
  useEffect(()=>{
    if(!show && isVisible) setShow(true)
  },[isVisible])  
  return (
    <div ref={ref}>
      {show && props.children}
    </div>
  );
};

export default LazyBox;
